module.exports = {
  lang: "中文",
  Home: "首页",
  Project: "项目",
  Data: "数据",
  Rank: "排行榜",
  BToken: "BToken",
  RPCServer: "RPC Server",
  GasPrice: "Gas Price",
  Submit: "提交",
  TVLDeFi: "BNB DeFi 总锁仓量",
  AvgGasFee: "平均手续费",
  TotalAddress: "总用户量",
  Txn24H: "24 小时交易量",
  Days7Ranking: "周排行榜",
  tvl: "总锁仓量",
  tvlChange: "总锁仓量 <br> 变化",
  address: "活跃用户",
  addressChange: "活跃用户 <br> 变化",
  txn: "交易量",
  txnChange: "交易量 <br> 变化",
  Token: "代币",
  price: "价格",
  percentChange: "价格 <br> 变化",
  ViewAll: "查看全部",
  ViewMore: "查看更多",
  NoInformation: "暂无数据",
  HighestAPYRecommend: "Highest APY Recommendation",
  BestAPYDApp: "Best APY in Hottest DApp",
  BTokensBestAPY: "BTokens Best APY",
  TopDAppRecommend: "Top DApp Recommendation",
  TopDAppNFT: "Top DApp on NFT",
  TopDAppDeFi: "Top DApp on DeFi",
  HottestCategories: "Hottest Categories",
  Exchange: "Exchange",
  Earn: "Earn",
  Lending: "Lending",
  Insureance: "Insureance",
  Meme: "Meme",
  Derivative: "Derivative",
  StableCoin: "StableCoin",
  More: "更多",
  Copy: "复制",
  CopySuccess: "复制成功",
  CopyFail: "请手动复制",
  NodeInfrastructure: "节点基础设施",
  BTokensMarketCap: "BTokens 市值",
  SearchTokenName: "根据代币名称搜索",
  UsedMarketCap: "Used Market <br> Cap",
  MarketCapChange: "市值 <br> 变化",
  UsedAmount: "Used Amount",
  AmountChange: "Amount <br> Change",
  Last7DaysAmt: "Last 7 Days Amt",
  AddDate: "Add Date",
  Contract: "合约",
  WhatIsBToken: "什么是 BToken? ",
  BasedPending: "基于 BSC 的待处理交易",
  Rapid: "极速",
  Fast: "高速",
  Standard: "一般",
  Slow: "缓慢",
  Seconds:"秒",
  Minute:"分钟",
  EstimatedCost: "交易手续费预估",
  Name: "名称",
  Label: "标签",
  Interactions: "交易",
  GasUsed: "Gas Used",
  Categories: "类别",
  All: "全部",
  Search: "搜索",
  NoResultsFound: "找不到与 w% 相关的结果",
  NoResultsInfo: "建议: <br>请确保所有字词拼写正确。<br> 尝试使用其他关键字。<br>尝试使用更宽泛的关键字。",
  NoMore: "暂无更多",
  Trade: "交易",
  PublishDate: "上线时间",
  event: "活动",
  VisitWebsite: "查看项目网站",
  Share: "分享",
  SubscribeToUpdates: "订阅更新",
  YourEmail: "输入邮箱",
  Subscribe: "订阅",
  SubmitThisProject: "提交有关此项目的更新",
  projectAboutInfo:"本网站上的所有信息纯粹是教育性的，仅应用于展示您的个人研究结果。 我们不提供投资建议，任何项目或方法的认可或任何结果的承诺。",
  D: "1 天",
  W:"1 周",
  M:"1 月",
  Y:"1 年",
  SubmitProject: "提交项目",
  Title:"标题",
  ProjectTitle: "项目标题",
  Category:"类别",
  SelectCategory: "选择类别",
  ShortIntroduction:"简介",
  ShortIntroductionLimit: "简介 (最多256个字符)",
  DetailDescription: "详细说明",
  Website: "网站链接",
  Email: "邮箱",
  SmartContractAddresses: "智能合约地址",
  SmartContractAddressesPlaceholder: "支持多个智能合约地址，用空格隔开",
  TokenSymbol: "代币名称",
  TokenContractAddress: "代币合约地址",
  EnterTitle:"请输入项目标题",
  EnterCategory:"请选择项目类别",
  EnterShortIntroduction:"请输入简介",
  EnterIntroductionLimit:"简介不能超过256字符",
  SubmitSuccess:"提交成功",
  Error: "失败",
  Popular: "热门",
  AsWeekData: "截至 d% 周数据",
  MVBTOTALTVL:"MVB TOTAL TVL",
  MVBActiveAddress:"MVActive address",
  TotalMVBProject:"Total MVB Project",
  Newjoiningprojects: "New joining projects",
  ApplyAccelerate:"Apply for accelerating package from BSC (MVB + Fund Investment)",
  Recommended:"推荐",
  Events: "活动",
  submitEventEntry:'如果您的活动未在此处显示,请<a class="color-primary" href="/#/eventsubmit">点击这里将您的活动信息提交</a>给我们.',
  submitProjectEntry:'如果您的项目未在此处显示，请<a class="color-primary" href="/#/projectsubmit">点击这里将您的项目信息提交</a>给我们.',
  AllCategories: "所有项目",
  NFTLandscape: "币安智能链 NFT 项目",
  SubmitEvent: "提交活动",
  Description: "项目简介",
  Image: "图片",
  ImageSize: "图片尺寸",
  URL: "链接",
  EventType: "活动类型",
  Other: "其他",
  Country: "国家",
  SelectCountry: "选择国家",
  Start: "开始时间",
  End: "结束时间",
  SubmitNFTProject: "提交 NFT 项目",
  SubmitNFTProjectTip: "在将您的项目提交给 NFT BIG BANG 之前，您应该首先将其提交给 BSCProject",
  SearchBSCProject:"从 BSCProject 中搜索项目",
  SearchProject: "搜索项目",
  Tag: "标签",
  SelectTags: "选择标签",
  EnterProject:"请选择项目",
  EnterTags:"请选择标签",
  EnterImage:"请上传图片",
  SearchNoProjectTip:"<i>找不到您的项目？ 请先在</i> <i class='color-primary'>这里提交</i>",
  Loading: "加载中...",
  Height: "块高",
  EnterDescription:"请输入项目简介",
  EnterEventType:"请选择活动类型",
  TotalValueLocked: "总锁仓量",
  About: "关于",
  Connect: "连接钱包",
  Disconnect: "断开连接",
  SelectLoginAccount: "选择一个插件钱包登录",
  NoExtension: "请先连接插件钱包",
  MyFavorites: "我的收藏",
  Favorites: "收藏",
  like: "收藏",
  Favorite: "收藏",
  Delete: "取消",
  Open: "打开",
  Operation: "操作",
  DevelopmentActivity: "开发活跃度",
  SecurityScore: "安全评分",
  Audited: "审计",
  Unaudited: "未审计",
  ConnectTip: "请刷新连接到当前 BSC 钱包",
  NetworkTip: "请切换到 BSC 网络",
  BalanceTip: "BNB 余额不足",
  ViewOnBscScan:"在 BscScan 上查看",
  Exchange: "交易",
  ShareThis: "分享",
  Prompt: "模版",
  Prompts: "模版",
  Projects: "项目",
  Tags: "标签",
  MyRewards: "我的奖励",
  Claim: "铸造",
  ClaimSuccess: "铸造成功",
  TokenRewardSystem: "Token Reward System",
  TaskList:[
    // {
    //   title:"Task 1",
    //   des:"Connect your wallet + 50 AIGC token",
    // },
    {
      title:"Task 1",
      des:"Invite one new user + 5 AIGC token",
      tip:"* Airdrop Batch 1: Max 500 AIGC token each address",
    },
  ],
  InvitationLink: "Invitation link",
  ClaimDetails: "Claim Details",
  ClaimLink: "Pending claims will be processed every Monday at 8:00 GMT. After that, you can mint in the link below:",
};
