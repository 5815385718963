import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import i18n from "./assets/js/i18n";
import lodash from "lodash";
import Toast from "./components/Toast";
import Loading from "./components/Loading";
import VueClipboard from "vue-clipboard2";
import ba from "vue-ba";
import webUtil from "./assets/js/util";
import VueLazyload from "vue-lazyload";
import "./assets/css/index.css";

Vue.config.productionTip = false;
Vue.use(VueLazyload, {
  preLoad: 1,
  error: require("./assets/img/icon/default.png"),
  loading: require("./assets/img/icon/default.png"),
  attempt: 1,
});
Vue.use(VueClipboard);
Vue.use(Toast);
Vue.use(Loading);

// 百度统计
Vue.use(ba, "183faf958e87829e284435d7e0e9bda3");
Vue.use(ba, { siteId: "183faf958e87829e284435d7e0e9bda3" });

import { mapGetters, mapActions } from "vuex";
Vue.mixin({
  data() {
    return {
      lodash: lodash,
      mainColor: "#f0b90b",
      webUtil: webUtil,
      axios: axios,
      signMessageStr: "AigcExchange",
      project_oss_link: "https://oss-us-cdn.maiziqianbao.net/bsc_project/",
      domain:
        process.env.NODE_ENV === "production"
          ? "https://api.staked.xyz/apiAigc/"
          : "/api/",
      contact: [
        "website",
        "email",
        "twitter",
        "telegram",
        "github",
        "job",
        // "coinmarketcap",
        "discord",
      ],
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLang",
      account: "account",
      user: "user",
    }),
  },
  methods: {
    ...mapActions(["setAccount", "logout"]),
    login() {
      return new Promise((resolve) => {
        try {
          this.user?.connectWallet(
            56,
            "https://bsc-dataseed4.ninicoin.io",
            async (address) => {
              if (address) {
                // 防止 metamask 切换账号自动刷新登录
                if (this.account) {
                  return false;
                }
                this.setAccount(address);
                // let sign = await this.user?.signMessage(this.signMessageStr);
                // let res = await this.axios.post(
                //   `${this.domain}walletConnect`,
                //   this.webUtil.qsStrify({
                //     address,
                //     sign,
                //     message: this.signMessageStr,
                //   })
                // );
                // if (res?.data?.success) {
                //   this.setAccount(address);
                //   resolve(address);
                // } else {
                //   this.logout();
                //   this.catchERR(res?.data?.message);
                // }
              } else {
                this.logout();
              }
            }
          );
        } catch (err) {
          this.logout();
          this.catchERR(err);
        }
      });
    },
    // 复制
    copyAction(val = window.location.href) {
      this.$copyText(val).then(
        () => {
          this.$toast("Successfully copied");
        },
        () => {
          this.$toast("Copy failed");
        }
      );
    },
    openLink(link, type) {
      if (type == "email") {
        window.location.href = "mailto:" + link;
      } else {
        window.open(link, "_blank");
      }
    },
    async sign() {
      if (!this.account) {
        await this.login();
      }
      this.$loading(1);

      let signRes = await this.user
        ?.signMessage(this.signMessageStr)
        .catch((err) => {
          this.$loading(0);
          this.catchERR(err);
        });

      return signRes;
    },
    catchERR(err) {
      if (err) {
        this.$toast(err);
      } else {
        this.logout();
        this.$toast(this.$t("SelectLoginAccount"));
      }
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
