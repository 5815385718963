import { providers } from "ethers";
import Onboard from "bnc-onboard";
import BitKeepIcon from "./BitKeepIcon";
import UAuthBncOnboard from "@uauth/bnc-onboard";

let clientIDs = {
  "https://launchpad.collectify.app": "617e0708-3c5f-4eb8-9c34-dd4846b7c371",
  "https://launchpad.collectify.dev": "5e575094-c7c6-4107-a5d1-10d3b188bc63",
  "http://localhost:8080": "5e575094-c7c6-4107-a5d1-10d3b188bc63",
};

const uauthOnboard = new UAuthBncOnboard({
  clientID: clientIDs[window.location.origin],
  redirectUri: window.location.origin,
  scope: "openid wallet",
});

class User {
  constructor() {
    this.provider = null;
    this.chainId = 56;
    this.onboard = null;
  }
  getEthereum() {
    return new Promise((resolve, reject) => {
      let tries = 0;
      let timer = setInterval(() => {
        tries++;
        if (window.ethereum) {
          clearInterval(timer);
          resolve(window.ethereum);
        }
        if (tries > 10) {
          clearInterval(timer);
          reject();
        }
      }, 100);
    });
  }
  logout() {
    let previous = localStorage.getItem("selectedWallet");
    if (previous == "Unstoppable") {
      uauthOnboard.getUAuth().then((uauth) => uauth.logout());
    }
    if (this.onboard) {
      this.onboard.walletReset();
    }
    localStorage.removeItem("selectedWallet");
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("e76c73571a73bc8f4e31967890b2337936ce693d");
  }

  async connectWallet(networkId, rpcUrl, accountCallback) {
    try {
      this.chainId = Number(networkId);
      let previous = localStorage.getItem("selectedWallet");
      if (previous == "WalletConnect") {
        previous = "";
        this.logout();
      }
      if (!this.onboard) {
        this.onboard = Onboard({
          dappId: "1c2d48a8-79ae-45ba-a56c-11bd194c5ca1",
          networkId: this.chainId,
          walletSelect: {
            wallets: [
              { walletName: "metamask" },
              { walletName: "mathwallet" },
              {
                walletName: "walletConnect",
                rpc: {
                  [networkId]: rpcUrl,
                },
              },
              uauthOnboard.module({
                preferred: false,
                walletconnect: {
                  infuraId: "4e1b02e3ab7e4877a0cf043079ace9e5",
                },
              }),
              { walletName: "binance" },
              { walletName: "walletLink", rpcUrl: rpcUrl },
              { walletName: "imToken", rpcUrl: rpcUrl },
              {
                name: "BitKeep",
                svg: BitKeepIcon,
                wallet: async (helpers) => {
                  const { createModernProviderInterface } = helpers;
                  const provider = window.bitkeep.ethereum;
                  const correctWallet = window?.bitkeep?.ethereum?.isBitKeep;

                  return {
                    provider,
                    interface: correctWallet
                      ? createModernProviderInterface(provider)
                      : null,
                  };
                },
                link: "https://bitkeep.com/",
                installMessage: (wallets) => {
                  const { currentWallet, selectedWallet } = wallets;
                  if (currentWallet) {
                    return `<p style="margin:12px 0">You have ${currentWallet} installed already but if you would prefer to use ${selectedWallet} instead, then click below to install.</p>`;
                  }

                  return `<p style="margin:12px 0">You will need to install ${selectedWallet} to continue. Click below to install.</p>`;
                },
                desktop: true,
                mobile: true,
              },
              { walletName: "blankwallet" },
              { walletName: "opera" },
              { walletName: "operaTouch" },
              { walletName: "torus" },
              { walletName: "status" },
              { walletName: "dcent" },
              { walletName: "huobiwallet", rpcUrl: rpcUrl },
              { walletName: "hyperpay" },
              { walletName: "wallet.io", rpcUrl: rpcUrl },
              { walletName: "frame" },
              { walletName: "ownbit" },
              { walletName: "alphawallet" },
              { walletName: "gnosis" },
              { walletName: "xdefi" },
              { walletName: "bitpie" },
              { walletName: "liquality" },
              { walletName: "tally" },
              { walletName: "1inch" },
              { walletName: "ronin" },
            ],
          },
          subscriptions: {
            // network: async (network) => {
            //   netCallback && netCallback(network)
            // },
            address: (address) => {
              accountCallback && accountCallback(address?.toLowerCase());
            },
            wallet: (wallet) => {
              if (wallet?.name) {
                this.onboard.walletCheck();
              }
              this.getProvider(wallet);
            },
          },
          // walletCheck: [
          //   { checkName: "connect" },
          //   { checkName: "balance" },
          //   { checkName: "derivationPath" },
          //   { checkName: "accounts" },
          // ],
        });
      }
      if (previous === "Unstoppable") {
        uauthOnboard
          .getUAuth()
          .then(async (uauth) => {
            await uauth.user();
            await this.onboard?.walletSelect("Unstoppable");
          })
          .catch(() => {
            window.localStorage.removeItem("selectedWallet");
          });
      } else {
        await this.onboard?.walletSelect(previous);
      }
    } catch (err) {
      throw err;
    }
  }

  async getProvider(wallet) {
    try {
      wallet = wallet || this.onboard?.getState()?.wallet;
      wallet?.name && localStorage.setItem("selectedWallet", wallet?.name);
      let eth = wallet?.provider || (await this.getEthereum());
      this.provider = new providers.Web3Provider(eth);
      return this.provider;
    } catch (err) {
      return null;
    }
  }

  async getSigner() {
    if (!this.provider) {
      await this.getProvider();
    }
    return await this.provider?.getSigner();
  }

  // 签名
  async signMessage(str) {
    let signer = await this.getSigner();
    let signRes = await signer.signMessage(str);
    return signRes;
  }
}

export default new User();
