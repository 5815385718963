import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../assets/js/i18n'
import user from '@/assets/js/user'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: i18n.locale,
    account: localStorage.getItem('aigc_account')?localStorage.getItem('aigc_account'):'', //当前账户地址
    user: user
  },
  getters: {
    lang(state) {
      return state.lang
    },
    account(state) {
      return state.account
    },
    user(state) {
      return state.user
    },
  },
  mutations: {
    setLang(state, val) {
      i18n.setUserLanguage(val)
      state.lang = val;
    },
    setAccount(state, val) {
      state.account = val;
      if ( val ) {
        localStorage.setItem('aigc_account', val)
      } else {
        localStorage.removeItem('aigc_account');
      }
    },
    logout(state) {
      state.account = null;
      user.logout();
      // 清除localStorage
      localStorage.removeItem('aigc_account');
    },
  },
  actions: {
    setLang(context, val) {
      context.commit('setLang', val)
    },
    setAccount(context, val) {
      context.commit('setAccount', val)
    },
    logout(context, val) {
      context.commit('logout', val)
    },
  },
})
