import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/",
    redirect: "/projects",
  },
  {
    path: "/models",
    name: "Models",
    component: () => import("../views/Models.vue"),
    props: (route) => ({
      cateID: route.query.cateID,
      tagID: route.query.tagID,
    }),
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("../views/Projects.vue"),
    props: (route) => ({
      cateID: route.query.cateID,
      tagID: route.query.tagID,
    }),
  },
  {
    path: "/project/:id",
    name: "Project",
    component: () => import("../views/Project.vue"),
    props: true,
  },
  {
    path: "/submit",
    name: "Submit",
    component: () => import("../views/Submit.vue"),
    meta: {
      noSubmit: true,
    },
  },
  {
    path: "/collections",
    name: "Collections",
    component: () => import("../views/Collections.vue"),
  },
  // {
  //   path: "/token",
  //   name: "Token",
  //   component: () => import("../views/Token.vue"),
  // },
  {
    path: "/invite",
    name: "Invite",
    component: () => import("../views/Invite.vue"),
    props: (route) => ({
      addr: route.query.addr,
    }),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
