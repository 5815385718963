<template>
  <div id="app">
    <Nav :hasSubmit="hasSubmit"/>
    <router-view class="main-content"/>
  </div>
</template>
<script>
import Nav from "./components/Nav";
export default {
  components: { Nav },
  data(){
    return {
      hasSubmit:true,
    }
  },
  created(){
    this.$router.afterEach(item => {
      // 百度统计
      this.$ba.trackPageview(item.path);
      if(item.meta.noSubmit){
        this.hasSubmit = false;
      }else{
        this.hasSubmit = true;
      }
    })
  },
};
</script>
<style scoped>
.main-content{
  min-height: calc(100vh - 64px);
}
@media(max-width:1024px) {
  .main-content{
    min-height: calc(100vh - 48px);
  }
}
/* @media(max-width:768px){
  .main-content{
    min-height: calc(100vh - 160px);
  }
} */
</style>
