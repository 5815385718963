module.exports = {
  lang: "English",
  Home: "Home",
  Project: "Project",
  Data: "Data",
  Rank: "Ranking",
  BToken: "BToken",
  RPCServer: "RPC Server",
  GasPrice: "Gas Price",
  Submit: "Submit",
  TVLDeFi: "Total Value Locked in BNB DeFi",
  DailyActiveUsers: "Daily Active Users",
  AvgGasFee: "Avg Gas Fee",
  TotalAddress: "Total Address",
  Txn24H: "24H Txn",
  Days7Ranking: "7 Days Ranking",
  tvl: "TVL",
  tvlChange: "TVL <br> Change",
  address: "Address",
  addressChange: "Address <br> Change",
  txn: "Txn",
  txnChange: "Txn <br> Change",
  Token: "Token",
  price: "Price",
  percentChange: "Price <br> Change",
  ViewAll: "View all",
  ViewMore: "View more",
  NoInformation: "No Information",
  HighestAPYRecommend: "Highest APY Recommendation",
  BestAPYDApp: "Best APY in Hottest DApp",
  BTokensBestAPY: "BTokens Best APY",
  TopDAppRecommend: "Top DApp Recommendation",
  TopDAppNFT: "Top DApp on NFT",
  TopDAppDeFi: "Top DApp on DeFi",
  HottestCategories: "Hottest Categories",
  Exchange: "Exchange",
  Earn: "Earn",
  Lending: "Lending",
  Insureance: "Insureance",
  Meme: "Meme",
  Derivative: "Derivative",
  StableCoin: "StableCoin",
  More: "More",
  Copy: "Copy",
  CopySuccess: "Copied Successfully",
  CopyFail: "Copy failed",
  NodeInfrastructure: "Node Infrastructure",
  BTokensMarketCap: "BTokens Market Cap",
  SearchTokenName: "Search by Token Name",
  UsedMarketCap: "Used Market <br> Cap",
  MarketCapChange: "Market Cap <br> Change",
  UsedAmount: "Used Amount",
  AmountChange: "Amount <br> Change",
  Last7DaysAmt: "Last 7 Days Amt",
  AddDate: "Add Date",
  Contract: "Contract",
  WhatIsBToken: "What is BToken? ",
  BasedPending: "Based On Pending Transaction of BSC",
  Rapid: "Rapid",
  Fast: "Fast",
  Standard: "Standard",
  Slow: "Slow",
  Seconds: "Seconds",
  Minute: "Minute",
  EstimatedCost: "Estimated Cost of Transactions",
  Name: "Name",
  Label: "Label",
  Interactions: "Interactions",
  GasUsed: "Gas Used",
  Categories: "Categories",
  All: "All",
  Search: "Search",
  NoResultsFound: "No results were found for w%",
  NoResultsInfo:
    "Suggest: <br>Please make sure all words are spelled correctly. <br> Please try a different keyword. <br> Please try a broader keyword.",
  NoMore: "No More",
  Trade: "Trade",
  event: "event",
  VisitWebsite: "Visit Website",
  Share: "Share",
  PublishDate: "Publish Date",
  SubscribeToUpdates: "Subscribe to Updates",
  YourEmail: "Your email",
  Subscribe: "Subscribe",
  SubmitThisProject: "Submit an update on this project",
  projectAboutInfo:
    "All info in this site is purely educational and should only be used to inform your own research. We're not offering investment advice, endorsement of any project or approach, or promise of any outcome.",
  D: "1D",
  W: "1W",
  M: "1M",
  Y: "1Y",
  SubmitProject: "Submit Project",
  Title: "Title",
  ProjectTitle: "Project title",
  Category: "Category",
  SelectCategory: "Select Category",
  ShortIntroduction: "Short Introduction",
  ShortIntroductionLimit: "Short Introduction (Limit 256 Characters)",
  DetailDescription: "Detail Description",
  Website: "Website",
  Email: "Email",
  SmartContractAddresses: "Smart Contract Addresses",
  SmartContractAddressesPlaceholder:
    "Support multiple smart contract addresses, separate them by spaces",
  TokenSymbol: "Token Symbol",
  TokenContractAddress: "Token Contract Address",
  EnterTitle: "Please fill in the title",
  EnterCategory: "Please select a category",
  EnterShortIntroduction: "Please fill in the short introduction",
  EnterIntroductionLimit: "Introduction is limited to 256 characters",
  SubmitSuccess: "Submitted successfully",
  Error: "Error",
  Popular: "Popular",
  AsWeekData: "As of the week of d%",
  MVBTOTALTVL: "MVB TOTAL TVL",
  MVBActiveAddress: "MVActive address",
  TotalMVBProject: "Total MVB Project",
  Newjoiningprojects: "New joining projects",
  ApplyAccelerate:
    "Apply for accelerating package from BSC (MVB + Fund Investment)",
  Recommended: "Recommended",
  Events: "Events",
  submitEventEntry:
    'If your Event is not shown here,please  <a class="color-primary" href="/#/eventsubmit">click here to submit your event information</a> to us.',
  submitProjectEntry:
    'If your project is not shown here, please <a class="color-primary" href="/#/projectsubmit">click here to submit your project information</a> to us.',
  AllCategories: "All Categories",
  NFTLandscape: "Binance Smart Chain NFT Landscape",
  SubmitEvent: "Submit Event",
  Description: "Description",
  Image: "Image",
  ImageSize: "Image Size",
  URL: "URL",
  EventType: "Event Type",
  Other: "Other",
  Country: "Country",
  SelectCountry: "Select Country",
  Start: "Start",
  End: "End",
  SubmitNFTProject: "Submit NFT Project",
  SubmitNFTProjectTip:
    "Before submiting your project to NFT BIG BANG, you should firstly Submit it to BSCProject",
  SearchBSCProject: "Search your project from BSCProject",
  SearchProject: "Search Project",
  Tag: "Tag",
  SelectTags: "Select Tags",
  EnterProject: "Please select project",
  EnterTags: "Please select tags",
  EnterImage: "Please upload image",
  SearchNoProjectTip:
    "<i>Can't find your project? Please firstly submit it</i> <i class='color-primary'>here</i>",
  Loading: "Loading...",
  Height: "Height",
  EnterDescription: "Please fill in the description",
  EnterEventType: "Please select event type",
  TotalValueLocked: "Total Value Locked",
  About: "About",
  Connect: "Connect",
  Disconnect: "Disconnect",
  SelectLoginAccount: "Please select a wallet to connect",
  NoExtension: "Please connect wallet first",
  MyFavorites: "My Favorites",
  Favorites: "Favorites",
  like: "Favorites",
  Favorite: "Favorite",
  Delete: "Delete",
  Open: "Open",
  Operation: "Operation",
  DevelopmentActivity: "Development Activity",
  SecurityScore: "Security Score",
  Audited: "Audited",
  Unaudited: "Unaudited",
  ConnectTip: "Please refresh and connect to your current BSC wallet",
  NetworkTip: "Please switch to BSC network",
  BalanceTip: "Insufficient BNB",
  ViewOnBscScan: "View on BscScan",
  Exchange: "Exchange",
  ShareThis: "Share this",
  Prompt: "Prompt",
  Prompts: "Prompts",
  Projects: "Projects",
  Tags: "Tags",
  MyRewards: "My Rewards",
  Claim: "Claim",
  ClaimSuccess: "Successfully claimed",
  TokenRewardSystem: "Token Reward System",
  TaskList:[
    // {
    //   title:"Task 1",
    //   des:"Connect your wallet + 50 AIGC token",
    // },
    {
      title:"Task",
      des:"Invite one new user + 5 AIGC token",
      tip:"* Airdrop Batch 1: Max 500 AIGC token each address",
    },
  ],
  InvitationLink: "Invitation link",
  ClaimDetails: "Claim Details",
  ClaimLink: "Pending claims will be processed every Monday at 8:00 GMT. After that, you can mint in the link below:",
  BackToHome: "Back to home",
  WebsiteNotExist: "This website does not exist",
};
